<script setup lang="ts">
import {
  faExternalLink,
  faLocationPin,
} from "@fortawesome/pro-regular-svg-icons";
import type { EventCard } from "~/types/events";

const props = defineProps<{ event: EventCard; hideWebinarLink?: boolean }>();

const city = computed(() => props.event.location?.city);
</script>

<template>
  <CardsEventInfoCell
    v-if="city && event.eventType !== 'webinar'"
    :icon="faLocationPin"
  >
    <span v-text="city" />
  </CardsEventInfoCell>
  <CardsEventInfoCell
    v-else-if="event.eventType === 'webinar' && !hideWebinarLink"
    :icon="faExternalLink"
  >
    <span>Webinar</span>
  </CardsEventInfoCell>
</template>
