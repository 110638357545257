<script setup lang="ts">
import { faCalendarDay } from "@fortawesome/pro-regular-svg-icons";
import type { EventCard } from "~/types/events";

const props = defineProps<{
  event: EventCard;
  weekday?: Intl.DateTimeFormatOptions["weekday"];
}>();

const length = computed(() => props.event.dates?.length ?? 0);

const monthStyle = computed(() => (length.value > 1 ? "numeric" : "long"));
</script>

<template>
  <CardsEventInfoCell v-if="length" :icon="faCalendarDay">
    <div class="flex flex-wrap gap-2">
      <time
        v-for="date in event.dates"
        :key="date.getTime()"
        :datetime="date.toDateString()"
        class="first-of-type:first-letter:capitalize"
        v-text="
          date.toLocaleDateString('no-NO', {
            weekday,
            day: 'numeric',
            month: monthStyle,
            year: 'numeric',
          })
        "
      />
    </div>
  </CardsEventInfoCell>
</template>

<style scoped>
time:not(:last-of-type)::after {
  content: ", ";
}
</style>
